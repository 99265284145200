import React from "react";
import { graphql } from "gatsby";
import { parseNewsData } from "../parsers";
import StaticPage from "../components/StaticPage";

const NewsTemplate = ({ data }) => {
  const language = "pl";
  const { title, html, tags } = parseNewsData({
    markdownRemark: data[language],
  });
  return (
    <StaticPage title={title} tags={tags}>
      <div
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </StaticPage>
  );
};

export default NewsTemplate;

export const query = graphql`
  query($path: String!) {
    pl: markdownRemark(fields: { slug: { eq: $path } }) {
      ...ArticleData
    }
  }
`;
